import { createStore } from 'vuex';

export default createStore({
  state: {
    // 当前路径
    href: sessionStorage.getItem("href"),
    activeIndex:sessionStorage.getItem("activeIndex"),
    load:false
  },
  getters: {},
  mutations: {
    setLoad(state, value){
      state.load = value
    },
    setHref(state, value){
      state.href = value
      sessionStorage.setItem(
        'href',
        value
      );
    },
    setActiveIndex(state, value){
      state.activeIndex = value
      sessionStorage.setItem(
        'activeIndex',
        value
      );
    },
  },
  actions: {},
  modules: {}
});
