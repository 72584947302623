<template>
  <div class="home" id="myElement">
    <div class="section section1" id="section1">
      <el-carousel class="carouselimg pc" :interval="6000">
        <el-carousel-item v-for="(item, index) in bannerPc" :key="index">
          <img :src="item.pic_url" style="width: 100%" />
        </el-carousel-item>
        <img
          class="pc"
          :src="require('@/assets/img/banner.png')"
          style="width: 100%; opacity: 0"
          alt=""
        />
      </el-carousel>
      <el-carousel class="carouselimg h5" :interval="6000">
        <el-carousel-item v-for="(item, index) in bannerH5" :key="index">
          <img :src="item.pic_url" style="width: 100%" />
        </el-carousel-item>
        <img
          class="h5"
          :src="require('@/assets/imgh5/banner.png')"
          style="width: 100%; opacity: 0"
          alt=""
        />
      </el-carousel>
    </div>

    <div class="section section2" id="section2" :key="styleKey1">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg2.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg2.png')" />

      <div class="contain2">
        <div class="g-top">
          <img class="g-icon" :src="require('@/assets/img/gameicon.png')" />
          <img
            class="g-more"
            @click="handleGo('/GameList', 2)"
            :src="require('@/assets/img/gamemore.png')"
          />
        </div>
        <!-- pc游戏 -->
        <div class="game-contain pc">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div v-for="item in totalPage" :key="item" class="swiper-slide">
                <div class="game-ul">
                  <div class="game-border" v-for="item1 in 4" :key="item1">
                    <div
                      class="game-k"
                      :style="
                        gameList[(item - 1) * 4 + (item1 - 1)]
                          ? ''
                          : 'border:none'
                      "
                    >
                      <img
                        v-if="gameList[(item - 1) * 4 + (item1 - 1)]"
                        class="gamebg"
                        :src="
                          gameList[(item - 1) * 4 + (item1 - 1)].game_banner
                        "
                      />
                    </div>
                    <div
                      class="name-div"
                      v-if="gameList[(item - 1) * 4 + (item1 - 1)]"
                    >
                      <img
                        class="gname-bg"
                        :src="require('@/assets/img/gnamebg.png')"
                      />
                      <div class="name-ab">
                        <div class="gname">
                          《{{
                            gameList[(item - 1) * 4 + (item1 - 1)].game_name
                          }}》
                        </div>
                        <!-- <div class="time">
                          {{
                            dateFormat(
                              gameList[(item - 1) * 4 + (item1 - 1)]
                                .start_time * 1000
                            )
                          }}
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            class="s-btn prev"
            :class="page == 1 ? 'noneGo' : ''"
            @click="handlePrev"
            :src="require('@/assets/img/s-left.png')"
          />
          <img
            @click="handleNext"
            :class="page == totalPage ? 'noneGo' : ''"
            class="s-btn next"
            :src="require('@/assets/img/s-right.png')"
          />
        </div>
        <!-- h5游戏 -->
        <div class="game-contain h5">
          <div class="h5-gameswiper">
            <div v-for="(item, index) in gameList" :key="index">
              <div class="game-border">
                <div class="game-k">
                  <img class="gamebg" :src="item.game_banner" />
                </div>
                <div class="name-div">
                  <img
                    class="gname-bg"
                    :src="require('@/assets/img/gnamebg.png')"
                  />
                  <div class="name-ab">
                    <div class="gname">《{{ item.game_name }}》</div>
                    <!-- <div class="time">
                      {{ dateFormat(item.start_time * 1000) }}
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section3" id="section3" :key="styleKey2">
      <img class="homebg2 pc" :src="require('@/assets/img/homebg3.png')" />
      <img class="homebg2 h5" :src="require('@/assets/imgh5/homebg3.png')" />

      <div class="contain3">
        <div class="about-contain">
          <img class="c-bg pc" :src="require('@/assets/img/aboutbg.png')" />
          <img class="c-bg h5" :src="require('@/assets/imgh5/aboutbg.png')" />

          <img
            class="abouticon2 h5"
            :src="require('@/assets/imgh5/abouticon2.png')"
          />
          <img
            class="abouticon1 h5"
            :src="require('@/assets/imgh5/abouticon1.png')"
          />

          <div class="right-about">
            <img
              class="abouticon2 pc"
              :src="require('@/assets/img/abouticon2.png')"
            />
            <img
              class="abouticon1 pc"
              :src="require('@/assets/img/abouticon1.png')"
            />

            <div class="about-font">
              <div>
                我们的使命是为玩家创造极致的游戏体验，成为游戏行业的领军者，不断开拓创新，打造更多经典之作，引领游戏潮流，创造辉煌未来。
              </div>

              <div class="font-b">
                我们将始终以玩家需求为导向，持续提升技术实力，为全球玩家带来更多精彩的游戏世界。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
// import { dateFormat } from "@/utils/dateFormat";
import Swiper from "swiper";
import "swiper/css/swiper.css";
// import { throttle } from "lodash";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();

// 动画重载
const scrollPosition1 = ref(0);
const styleKey1 = ref(0);
const styleKey2 = ref(0);
// 初始化数据
const webId = ref();
const bannerPc = ref([]);
const bannerH5 = ref([]);
const gameList = ref([]);
const totalPage = ref(1);
const page = ref(1);
// 初始化
const initData = async () => {
  return new Promise((reslove) => {
    let params = {
      host: window.location.host,
    };
    proxy.$axios.post("/Index/initData", params).then(async (res) => {
      if (res.code == 200) {
        webId.value = res.data.company_id;
        res.data.carousels.forEach((x) => {
          if (x.type == "h5") {
            bannerH5.value.push(x);
          } else {
            bannerPc.value.push(x);
          }
        });
        await getGame();
        reslove();
      }
    });
  });
};
// 获取游戏轮播
const getGame = async () => {
  return new Promise((reslove) => {
    let params = {
      company_id: webId.value,
      type: "index",
    };
    proxy.$axios.post("/Index/getGames", params).then((res) => {
      if (res.code == 200) {
        gameList.value = res.data;
        totalPage.value = Math.ceil(gameList.value.length / 4);
        reslove();
      }
    });
  });
};
// 轮播按钮点击
const handlePrev = () => {
  if (page.value > 1) {
    page.value = page.value - 1;
  }
};
const handleNext = () => {
  if (page.value < totalPage.value) {
    page.value = page.value + 1;
  }
};
// 跳转页面
const handleGo = (val, n) => {
  proxy.$router.push({
    path: val,
  });
  store.commit("setActiveIndex", n);
};
// 滑动监测
const handleScroll = () => {
  const containerHeight = window.innerHeight;
  const scrollPosition = document.documentElement.scrollTop;
  if (scrollPosition <= scrollPosition1.value) {
    return;
  }
  scrollPosition1.value = scrollPosition;
  // 假设您有一个元素的ID为'myElement'
  const element = document.getElementById("myElement");
  const wHeight = element.offsetHeight;
  if (
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
  ) {
    if (
      scrollPosition >= 0 &&
      scrollPosition <= (wHeight - containerHeight) * 0.5
    ) {
      styleKey1.value = styleKey1.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.5 &&
      scrollPosition <= (wHeight - containerHeight) * 0.8
    ) {
      styleKey2.value = styleKey2.value + 1;
    }
  } else {
    if (
      scrollPosition >= 0 &&
      scrollPosition <= (wHeight - containerHeight) * 0.37
    ) {
      styleKey1.value = styleKey1.value + 1;
    } else if (
      scrollPosition > (wHeight - containerHeight) * 0.37 &&
      scrollPosition <= (wHeight - containerHeight) * 0.8
    ) {
      styleKey2.value = styleKey2.value + 1;
    }
  }
};
onMounted(async () => {
  window.addEventListener("scroll", handleScroll);
  store.commit("setLoad", false);
  await initData();
  // 设置轮播图
  const mySwiper = new Swiper(".swiper-container", {
    loop: false,
    centeredSlides: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },
  });
  mySwiper;
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>
<style src="./home.css" scoped></style>
<style scoped>
</style>
