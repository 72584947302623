export default {
    Nav: {
        Home: 'Home',
        About: 'About',
        Game: 'Game',
        Joinus: 'Join Us',
        contactUs: 'Contact Us',
        secret:'Policy',
        terms:'Terms',
        safe:'Account Safety'
    },
    Footer: {
        cont: "Copyright {'@'} 2011 All Right Reserved YOULE NETWORK TECHNOLOGY CO.,LIMITED"
    },
    commencont:{
        Coming: 'Coming soon'
    },
    contactus: {
        address:'ROOM 63, 7/F, WOON LEE COMMERCIAL BUILDING, 7-9 AUSTIN AVE, TSIM SHA TSUI, KOWLOON, HONGKONG,CHINA',
        title:'YOULE FUN GAMES'
    },
    Aboutus:{
        about1: 'YOULE NETWORK TECHNOLOGY CO.,LIMITED established in May 2023 and head office located in Hong Kong . China, is a comprehensive interactive entertainment enterprise with mobile game as its core and integrating research, development, operation and distribution.',
        about2: 'The core team members of the company are from world-famous game companies, and all have rich experience in game research and development and operation, and have been committed to taking independent innovation as the cornerstone, relying on strong technical strength and creative game design capabilities, many card, strategy, MMORPG, and Casual games are under intense research and development. The games will be released to global markets such as Hong Kong, Macao and Taiwan, Japan, South Korea, Europe, the Middle East, and Southeast Asia.',
        about3: 'In the future, YouLe Fun will continue to develop the absolute best products possible, ones that exceed users’ expectations and bring fun, exciting entertainment and high-quality services to players everywhere.',
    },
    title:{
        title: 'YOULE FUN GAMES',
        Keywords: 'xin sheng games,mobile games,netgame,android,apple game,ios game,publisher,overseas game,hong kong games,google play games,appstore,download,install,Samsung,huawei,oppo,vivo,meta,vr game, war game,strategy game, revenge, war strategy, free games, online games, mobile app, arabic game,RTS, MMO,world game',
        Description:'A mobile game develop and publisher。'
    },
    Secret:{
        src:'/static/policy-en.html'
    },
    Terms:{
        src:'/static/terms-en.html'
    },
    Account:{
        line1:'If you want to delete your account, please fill in (the deleted account will not be recovered):',
        line2:'Reminder: If you log into through Google or Facebook, please create your account name and password in Account Management.',
        line3:"Please send your UID, account name and password to destinyoraclecs{'@'}gmail.com",
        form1:'please input your uid',
        form2:'please input your account',
        form3:'please input your password',
        btn1:'Delete Account',
        btn2:'Delete data',
        btn3:'Cancel',
        btn4:'Confirm'
    }
}
