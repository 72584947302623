import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import App from './App.vue'
import router from './router'
import store from './store';
import {api} from '@/utils/request'
import '@/utils/rem'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


import i18n from "./lang/index";
import './assets/css/common.css'
const app = createApp(App)
app.config.globalProperties.$axios = api;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store)
app.use(ElementPlus)
app.use(i18n) // 挂载语言包
app.use(router)
app.mount('#app')
