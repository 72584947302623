<template>
  <div class="top-nav">
    <Nav />
  </div>
  <div style="width: 100%">
    <router-view></router-view>
    <Footer />
  </div>
  <!--  <img alt="Vue logo" src="./assets/logo.png">-->
</template>

<script>
import Nav from "./components/Nav.vue";
import Footer from "./components/footer.vue";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
.top-nav {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
</style>
