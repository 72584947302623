<template>
  <div class="affix-container pc">
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
    >
      <el-menu-item class="logobtn" index="1" style="margin-right: 50px">
        <el-image
          class="imgw145"
          style="width: 100%"
          :src="require('@/assets/img/logo.png')"
        />
      </el-menu-item>
      <el-menu-item index="1">
        首页
        <img
          v-show="activeIndex == '1'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="2" style="position: relative" class="about-menu">
        <div>游戏</div>
        <img
          v-show="activeIndex == '2'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="3"
        >关于再乐
        <img
          v-show="activeIndex == '3'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
      <el-menu-item index="4"
        >加入再乐
        <img
          v-show="activeIndex == '4'"
          class="menu-index"
          :src="require('@/assets/img/menuindex.png')"
        />
      </el-menu-item>
    </el-menu>
  </div>
  <!-- H5 顶部 -->
  <div class="h5-top h5">
    <img class="top-icon" :src="require('@/assets/imgh5/logo.png')" />
    <img
      v-if="!menuShow"
      class="top-more"
      @click="handleShowMenu"
      :src="require('@/assets/imgh5/more.png')"
    />
    <img
      v-else
      class="top-more"
      @click="handleCloseMenu"
      :src="require('@/assets/imgh5/close.png')"
    />

    <!-- h5菜单 -->
    <div
      class="menu-zhe"
      v-if="menuShow"
      @click="
        () => {
          menuShow = false;
        }
      "
    >
      <div class="h5-menu">
        <img class="h5-m-bg" :src="require('@/assets/imgh5/menubg.png')" />

        <div
          class="menu-li"
          :class="activeIndex == '1' ? 'active-li' : ''"
          @click.stop="handleSelect('1')"
        >
          首页
        </div>
        <div
          class="menu-li"
          style="top: 1.22rem"
          :class="activeIndex == '2' ? 'active-li' : ''"
          @click.stop="handleSelect('2')"
        >
          游戏
        </div>
        <div
          class="menu-li"
          style="top: 2.44rem"
          @click.stop="handleSelect('3')"
          :class="activeIndex == '3' ? 'active-li' : ''"
        >
          关于再乐
        </div>
        <div
          class="menu-li"
          style="top: 3.66rem"
          @click.stop="handleSelect('4')"
          :class="activeIndex == '4' ? 'active-li' : ''"
        >
          加入再乐
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
const store = useStore();
const activeIndex = ref(store.state.activeIndex || "1");
const router = useRouter();
const menuShow = ref(false);
const href = computed(() => store.state.href);
const activeIndex1 = computed(() => store.state.activeIndex);
watch(activeIndex1, (newV) => {
  activeIndex.value = newV;
});
const handleShowMenu = () => {
  menuShow.value = true;
};
const handleCloseMenu = () => {
  menuShow.value = false;
};
// 菜单点击
const handleSelect = (key) => {
  menuShow.value = false;
  store.commit("setActiveIndex", key);
  sessionStorage.setItem("active", key);
  if (key == "1") {
    if (href.value === "/") return;
    router.push({ path: "/" });
  } else if (key == "2") {
    if (href.value === "/GameList") return;
    router.push({ path: "/GameList" });
  } else if (key == "3") {
    if (href.value === "/auto") return;
    router.push({ path: "/auto" });
  } else if (key == "4") {
    if (href.value === "/join") return;
    router.push({ path: "/join" });
  }
};
</script>

<style scoped>
.imgw145 {
  display: flex;
  align-items: center;
}
.menu-nav {
  position: absolute;
  top: 70px;
  left: 0px;
}
.menu-bug {
  width: 100%;
}
.about-menu .menu-nav {
  display: none;
}
.about-menu:hover .menu-nav {
  display: block;
}
.nav-ul {
  position: absolute;
  left: 0;
  top: 10%;
  width: 100%;
  height: 90%;
}
.nav-li {
  height: 33%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.nav-li:hover {
  color: #ff702c;
}
.menu-index {
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
}
.affix-container {
  background: url("../../src/assets/img/topline.png");
  height: 80px;
}
.el-menu {
  width: 92%;
  margin: 0 auto;
  background-color: initial;
  border-bottom: none;
  height: 80px;
}
.el-menu-item {
  width: 160px;
}
.flex-grow {
  flex-grow: 1;
}
/deep/.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  color: #ff702c !important;
}
.el-menu--horizontal > .el-menu-item {
  color: #474747;
  font-size: 20px;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #474747 !important;
}
/deep/ .el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: inherit;
  color: #ff702c;
  border-bottom: none;
}
.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover {
  background-color: inherit;
  color: #ff702c;
  border-bottom: none;
}
.m-1 {
  margin-top: 30px;
  width: 140px;
}
@media screen and (max-width: 750px) {
  .h5-top {
    width: 7.5rem;
    height: 1rem;
    background: url("../../src/assets/imgh5/topline.png");
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
  }
  .top-icon {
    width: 2rem;
  }
  .top-more {
    width: 0.5rem;
  }
  .menu-zhe {
    position: fixed;
    top: 1rem;
    left: 0;
    width: 7.5rem;
    height: calc(100% - 1rem);
    background: #0000005e;
    z-index: 999;
  }
  .h5-menu {
    position: absolute;
    right: 0;
    top: 0;
  }
  .h5-m-bg {
    width: 7.5rem;
  }
  .menu-li {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1.22rem;
    line-height: 1.22rem;
    text-align: center;
    color: #474747;
    font-size: 18px;
  }
  .menu-index {
    position: absolute;
    width: 0.2rem;
    top: 0.55rem;
    left: 0;
  }
  .active-li {
    color: #ff702c;
  }
}
</style>
