import { createRouter, createWebHashHistory } from 'vue-router'
import  useStore  from "@/store/index.js";

import Home from '@/views/Home/home'
import auto from '@/views/auto/auto'
import Gamelist from '@/views/Game/Gamelist'
import join from '@/views/join/join'


//路由数组
const routes = [
    {
        //基本格式
        path: "/",
        name: "Home",
        component: Home,
        meta:{
            title:'首页'
        }
    },
    {
        //基本格式
        path: "/auto",
        name: "Auto",
        component: auto,
        meta:{
            title:'关于我们'
        }
    },
    {
        //基本格式
        path: "/Gamelist",
        name: "Gamelist",
        component: Gamelist,
    },
    {
        path:"/join",
        name: "join",
        component: join,
    }
]
//路由对象
const router = createRouter({
    mode: 'hash',
    history: createWebHashHistory(),
    routes //上面的路由数组
})
const store = useStore
router.beforeEach((to, from, next) => {
    store.commit('setHref',to.path)
    next(); // 继续导航
})
router.afterEach((to, from) => {
    if(to.path === from.path) return
    window.scrollTo(0, 0); // 每次路由切换后滚动到顶部
})


//导出路由对象，在main.js中引用
export default router
