import axios from 'axios';
// import {  ElMessage } from 'element-plus';
// import cookie from 'vue-cookie'

const axiosFn = function (baseUrl) {

  const instance = axios.create({
    timeout: 1000000,
    baseURL: baseUrl,
    headers: {

    }
  });
  //请求拦截
  instance.interceptors.request.use(
    (config) => {
      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  //响应拦截
  instance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  return instance;
};

const baseURL = process.env.VUE_APP_API_BASEURL

export const api = axiosFn(baseURL);
