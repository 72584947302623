<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/gamebg1.jpg')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/gamebg1.png')" />

      <img class="top-font1" :src="require('@/assets/img/topf1.png')" />
      <img class="top-font2" :src="require('@/assets/img/topf2.png')" />
    </div>
    <div class="section2 section" id="section2">
      <img class="aboutbg2 pc" :src="require('@/assets/img/aboutbg2.png')" />

      <div class="contain">
        <img class="left-img pc" :src="require('@/assets/img/building.png')" />
        <img
          class="left-img h5"
          :src="require('@/assets/imgh5/building.png')"
        />
        <div class="right-text">
          <img class="name-icon" :src="require('@/assets/img/nameicon.png')" />
          <img class="fenge" :src="require('@/assets/img/fenge.png')" />
          <div class="text-desc">
            <p>
              浙江再乐网络科技有限公司自 2020
              年全力开展游戏业务，以创新与品质为追求，汇聚了行业内顶尖的技术和开发人才。
            </p>
            <p>
              我们的使命是为玩家创造极致的游戏体验，成为游戏行业的领军者，不断开拓创新，打造更多经典之作，引领游戏潮流，创造辉煌未来。
            </p>
            <p>
              公司已成功推出包括《傲世龙城》、《龙途天下》，《黑暗光年》，《暴龙世界》以及
              24 年《武林霸业》在内的 5 款爆款游戏产品，均取得了令人瞩目的成绩。
            </p>
            <p>
              我们将始终以玩家需求为导向，持续提升技术实力，为全球玩家带来更多精彩的游戏世界。
            </p>
          </div>
          <img class="dianzhui" :src="require('@/assets/img/dianzhui.png')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
store.commit('setLoad', true)
onMounted(async () => {
  store.commit('setLoad', false)
})
</script>
<style src="./auto.css" scoped></style>
<style scoped>
</style>
