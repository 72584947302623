<template>
  <div class="auto_css" id="myElement">
    <!-- 刚进入 -->
    <div class="section1 section" id="section1" v-if="showDiv == 1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/joinbg1.png')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/joinbg1.png')" />

      <img class="top-font1 div1" :src="require('@/assets/img/topf1.png')" />
      <img class="top-font2 div2" :src="require('@/assets/img/topf2.png')" />

      <!-- 搜索框 -->
      <div class="input-item one-input">
        <input
          type="text"
          class="s-input"
          v-model="searchText"
          placeholder="输入关键词搜索职位"
        />
        <img
          class="s-icon"
          @click="handleSearch"
          :src="require('@/assets/img/s-icon.png')"
        />
      </div>

      <!-- 进入招聘列表 -->
      <img
        class="j-go"
        @click="
          () => {
            showDiv = 2;
          }
        "
        :src="require('@/assets/img/j-go.png')"
      />
    </div>
    <!-- 招聘列表 -->
    <div
      class="section1 section m-t"
      id="section1"
      v-if="showDiv == 2 || showDiv == 3"
    >
      <img class="aboutbg1 pc" :src="require('@/assets/img/gamebg1.jpg')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/gamebg1.png')" />

      <img class="top-font1" :src="require('@/assets/img/topf1.png')" />
      <img class="top-font2" :src="require('@/assets/img/topf2.png')" />
    </div>
    <div
      class="section2 section"
      id="section2"
      v-if="showDiv == 2 || showDiv == 3"
    >
      <!-- 职位列表  -->
      <div class="j-contain" v-if="showDiv == 2">
        <!-- 搜索框 -->
        <div class="search-top">
          <img class="s-top pc" :src="require('@/assets/img/s-bg.png')" />
          <img class="s-top h5" :src="require('@/assets/imgh5/s-bg.png')" />

          <div class="s-contain">
            <div class="input-item two-input">
              <input
                type="text"
                class="s-input"
                v-model="searchText"
                placeholder="输入关键词搜索职位"
              />
              <img
                class="s-icon"
                @click="getJoinList"
                :src="require('@/assets/img/s-icon-w.png')"
              />
            </div>

            <div class="select-item">
              <div class="select">
                <div class="select-input">
                  <span v-if="searchType == 0">职位类别</span>
                  <span v-else>{{ searchType }}</span>
                </div>
                <div class="select-right" @click="handleShowOption(1)">
                  <el-icon v-if="showOption != 1"><ArrowDown /></el-icon>
                  <el-icon v-if="showOption == 1"><ArrowUp /></el-icon>
                </div>

                <div class="select-option" v-show="showOption == 1">
                  <div
                    class="option"
                    @click="setSearchOption(1, 0)"
                    :style="typeList.length == 0 ? 'border-bottom:none' : ''"
                  >
                    全部
                  </div>
                  <div
                    class="option"
                    :style="
                      index === typeList.length - 1 ? 'border-bottom:none' : ''
                    "
                    v-for="(item, index) in typeList"
                    :key="index"
                    @click="setSearchOption(1, item.job_type)"
                  >
                    {{ item.job_type }}
                  </div>
                </div>
              </div>
              <div class="select">
                <div class="select-input">
                  <span v-if="searchStyle == 0">工作性质</span>
                  <span v-else>{{ searchStyle }}</span>
                </div>
                <div class="select-right" @click="handleShowOption(2)">
                  <el-icon v-if="showOption != 2"><ArrowDown /></el-icon>
                  <el-icon v-if="showOption == 2"><ArrowUp /></el-icon>
                </div>

                <div class="select-option" v-show="showOption == 2">
                  <div class="option" @click="setSearchOption(2, 0)">全部</div>
                  <div class="option" @click="setSearchOption(2, '正式')">
                    正式
                  </div>
                  <div
                    class="option"
                    style="border-bottom: none"
                    @click="setSearchOption(2, '实习')"
                  >
                    实习
                  </div>
                </div>
              </div>

              <div class="s-btn" @click="getJoinList">搜索</div>
            </div>
          </div>
        </div>

        <!-- 职位列表 -->
        <div class="job-item">
          <!-- 表内容 -->
          <div
            class="tip-text"
            v-if="!tableList.length || tableList.length == 0"
          >
            这里暂时还没有内容噢~
          </div>
          <div v-else class="t-body-all">
            <div
              class="job-li"
              v-for="(item, index) in tableList"
              :key="index"
              @click="hanleGoDetail(item)"
            >
              <img class="job-bg" :src="require('@/assets/img/jobbg.png')" />

              <div class="job-contain">
                <div class="job-name">{{ item.job_name }}</div>
                <div class="job-style">
                  工作性质<span style="margin: 0 10px">|</span
                  >{{ item.job_attr }}
                </div>
                <div class="job-type">{{ item.job_type }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 职位详情 -->
      <div class="j-contain" v-if="showDiv === 3">
        <div class="job-item detail-item">
          <!-- 返回按钮 -->
          <div class="b-btn" @click="handleBack">
            <el-icon><ArrowLeft /></el-icon> 返回
          </div>
          <!-- 岗位信息 -->
          <div class="job-top">
            <div class="jt-line">
              <div class="j-name">{{ detail.job_name }}</div>
              <div class="job-type">{{ detail.job_type }}</div>
            </div>
            <div class="job-style">
              工作类型<span style="margin: 0 10px">|</span>{{ detail.job_attr }}
            </div>
          </div>
          <!-- 岗位详情 -->
          <div class="j-detail">
            <div class="title">职位描述</div>
            <div class="d-text" v-html="detail.job_desc"></div>
            <div class="title">职位要求</div>
            <div class="d-text" v-html="detail.job_request"></div>
            <div class="title">联系方式：{{ detail.job_tel }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
// 搜索
const showDiv = ref(1);
const typeList = ref([]);
const searchText = ref("");
const showOption = ref(0);
const searchType = ref(0);
const searchStyle = ref(0);
const tableList = ref([]);
const webId = ref();
// 详情
const detail = ref();

// 初始化
const initData = async () => {
  let params = {
    host: window.location.host,
  };
  proxy.$axios.post("/Index/initData", params).then(async (res) => {
    if (res.code == 200) {
      webId.value = res.data.company_id;
      getTypeList();
      getJoinList();
    }
  });
};
initData();
// 首图搜索
const handleSearch = () => {
  showDiv.value = 2;
  getJoinList();
};
// 下拉列表展示
const handleShowOption = (val) => {
  if (val == showOption.value) {
    showOption.value = 0;
    return;
  }
  showOption.value = val;
};
// 下拉赋值
const setSearchOption = (type, val) => {
  if (type == 1) {
    searchType.value = val;
  } else if (type == 2) {
    searchStyle.value = val;
  }
  showOption.value = 0;
};
// 获取招聘类型
const getTypeList = () => {
  proxy.$axios.post("/User/getJobTypes").then(async (res) => {
    if (res.code == 200) {
      typeList.value = res.data;
    }
  });
};
// 获取招聘列表
const getJoinList = () => {
  let params = {
    job_type: searchType.value,
    job_attr: searchStyle.value,
    company_id: webId.value,
    job_name: searchText.value,
  };
  proxy.$axios.post("/Index/getJobs", params).then(async (res) => {
    if (res.code == 200) {
      tableList.value = res.data;
    }
  });
};
// 查看详情
const hanleGoDetail = (item) => {
  detail.value = item;
  showDiv.value = 3;
};
const handleBack = () => {
  showDiv.value = 2;
};
onMounted(async () => {
  store.commit("setLoad", false);
});
</script>
<style src="./join.css" scoped></style>
<style scoped>
</style>
  