<template>
  <div class="auto_css" id="myElement">
    <div class="section1 section" id="section1">
      <img class="aboutbg1 pc" :src="require('@/assets/img/gamebg1.jpg')" />
      <img class="aboutbg1 h5" :src="require('@/assets/imgh5/gamebg1.png')" />

      <img class="top-font1" :src="require('@/assets/img/topf1.png')" />
      <img class="top-font2" :src="require('@/assets/img/topf2.png')" />
    </div>
    <div class="section2 section" id="section2">
      <img class="aboutbg2 pc" :src="require('@/assets/img/gamebg2.png')" />
      <!-- 筛选 -->
      <div class="search-contain">
        <div>筛选：</div>
        <div class="select">
          <div class="select-input">
            <span v-if="searchType == 0">全部平台</span>
            <span v-if="searchType == 1">移动</span>
            <span v-if="searchType == 2">网页</span>
            <span v-if="searchType == 3">PC</span>
          </div>
          <div class="select-right" @click="handleShowOption">
            <el-icon v-if="!showOption"><ArrowDown /></el-icon>
            <el-icon v-else><ArrowUp /></el-icon>
          </div>

          <div class="select-option" v-show="showOption">
            <div class="option" @click="getGame(0)">全部平台</div>
            <div class="option" @click="getGame(1)">移动</div>
            <div class="option" @click="getGame(2)">网页</div>
            <div class="option" style="border-bottom: none" @click="getGame(3)">
              PC
            </div>
          </div>
        </div>
      </div>
      <div class="contain">
        <!-- pc -->
        <div
          class="game-item pc"
          v-for="(item, index) in showPcList"
          :key="index"
          :style="index % 3 == 2 ? 'margin-right:0' : ''"
        >
          <img class="glbg" :src="item.game_banner2" />

          <!-- 悬浮介绍 -->
          <div class="float-game">
            <div class="float-top">
              <img class="game-icon" :src="item.game_icon" />
              <div class="float-right">
                <div class="name">《{{ item.game_name }}》</div>
                <div
                  class="desc"
                  :style="
                    item.game_url
                      ? '-webkit-line-clamp: 2'
                      : '-webkit-line-clamp: 4'
                  "
                >
                  {{ item.game_desc }}
                </div>
              </div>
            </div>
            <div class="float-bottom" v-if="item.game_url">
              <div class="down-btn" @click="handleGoOffice(item)">进入官网</div>
            </div>
          </div>
        </div>
        <!-- 下方切换按钮 -->
        <div class="swiper-btn">
          <img
            class="s-btn pc"
            :class="pcPage == 1 ? 'noneGo' : ''"
            :src="require('@/assets/img/left.png')"
            @click="handlePrev"
          />
          <img class="line1 pc" :src="require('@/assets/img/line1.png')" />
          <img
            class="s-btn pc"
            :class="pcPage == totalPage ? 'noneGo' : ''"
            :src="require('@/assets/img/right.png')"
            @click="handleNext"
          />
        </div>

        <!-- h5  -->
        <div
          v-for="(item, index) in gameList"
          :key="index"
          class="game-item h5"
        >
          <img class="glbg" :src="item.game_banner2" />

          <!-- 悬浮介绍 -->
          <div class="float-game">
            <div class="float-top">
              <img class="game-icon" :src="item.game_icon" />
              <div class="float-right">
                <div class="name">《{{ item.game_name }}》</div>
                <div
                  class="desc"
                  :style="
                    item.game_url
                      ? '-webkit-line-clamp: 2'
                      : '-webkit-line-clamp: 4'
                  "
                >
                  {{ item.game_desc }}
                </div>
              </div>
            </div>
            <div class="float-bottom" v-if="item.game_url">
              <div class="down-btn" @click="handleGoOffice(item)">进入官网</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
// import { dateFormat } from "@/utils/dateFormat";
import { useStore } from "vuex";
const store = useStore();
store.commit("setLoad", true);
const { proxy } = getCurrentInstance();
// pc展示的游戏列表4个
const pcPage = ref(1);
const gameList = ref([]);
const totalPage = ref(1);
const showPcList = ref([]);
const webId = ref();
const searchType = ref(0);
const showOption = ref(false);
// 初始化
const initData = async () => {
  let params = {
    host: window.location.host,
  };
  proxy.$axios.post("/Index/initData", params).then(async (res) => {
    if (res.code == 200) {
      webId.value = res.data.company_id;
      getGame(0);
    }
  });
};
initData();
// 筛选下拉
const handleShowOption = () => {
  showOption.value = !showOption.value;
};
// 获取游戏
const getGame = async (val) => {
  searchType.value = val;
  let plat = "";
  if (val == 0) {
    plat = "";
  } else if (val == 1) {
    plat = "移动";
  } else if (val == 2) {
    plat = "网页";
  } else if (val == 3) {
    plat = "PC";
  }
  let params = {
    company_id: webId.value,
    platform: plat,
    type: "list",
  };
  proxy.$axios.post("/Index/getGames", params).then((res) => {
    if (res.code == 200) {
      gameList.value = res.data;
      totalPage.value = Math.ceil(gameList.value.length / 6);
      showOption.value = false;
      getPcList();
    }
  });
};
// pc展示哪些游戏
const getPcList = () => {
  // showPcList.value = [];
  let list = [];
  if (gameList.value[(pcPage.value - 1) * 6]) {
    list.push(gameList.value[(pcPage.value - 1) * 6]);
  }
  if (gameList.value[(pcPage.value - 1) * 6 + 1]) {
    list.push(gameList.value[(pcPage.value - 1) * 6 + 1]);
  }
  if (gameList.value[(pcPage.value - 1) * 6 + 2]) {
    list.push(gameList.value[(pcPage.value - 1) * 6 + 2]);
  }
  if (gameList.value[(pcPage.value - 1) * 6 + 3]) {
    list.push(gameList.value[(pcPage.value - 1) * 6 + 3]);
  }
  if (gameList.value[(pcPage.value - 1) * 6 + 4]) {
    list.push(gameList.value[(pcPage.value - 1) * 6 + 4]);
  }
  if (gameList.value[(pcPage.value - 1) * 6 + 5]) {
    list.push(gameList.value[(pcPage.value - 1) * 6 + 5]);
  }
  showPcList.value = list;
};
// pc下方按钮点击
const handlePrev = () => {
  if (pcPage.value == 1) return;
  pcPage.value = pcPage.value - 1;
  getPcList();
};
const handleNext = () => {
  if (pcPage.value == totalPage.value) return;
  pcPage.value = pcPage.value + 1;
  getPcList();
};
// 跳转到游戏官网
const handleGoOffice = (item) => {
  window.location = item.game_url;
};
onMounted(async () => {
  store.commit("setLoad", false);
});
</script>
<style src="./game.css" scoped></style>
<style scoped>
</style>
